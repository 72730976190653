<template>
  <div id="rfmEventFilter">
    <div v-if="!fetchingData">
      <div class="w-full p-3">
        <!-- RFM Event Picker -->
        <div class="eventFilter mt-0 text-sm" v-bind:class="{ readOnly: readOnly === true }">
          <div class="text-xs text-gray-500" style="margin-bottom: 5px">Select Event for RFM analysis</div>
          <!-- Header -->
          <div v-if="!fetchingData">
            <el-select class="addNewEventBtn" size="mini" v-if="!readOnly" v-model="selectedEvent.eventName" filterable @change="onEventSelect()" placeholder="Select Action Event">
              <el-option-group v-for="eventGroup in eventList" :key="eventGroup.label" :label="eventGroup.label">
                <el-option v-for="eventName in eventGroup.options" :key="eventName" :label="eventName" :value="eventName"> </el-option>
              </el-option-group>
            </el-select>
            <span class="eventNameFeild" v-else>{{ selectedEvent.eventName }}</span>
          </div>

          <!-- Filter Of Particular Event -->
          <div class="propertyFilterList" v-if="selectedEvent.filters.length > 0">
            <div class="propertyFilter" v-for="(subFilter, index1) in selectedEvent.filters" :key="index1">
              <template v-if="!readOnly">
                <span style="font-size: 12px; color: #444; line-height: 26px"> <span v-if="index1 != 0">and </span>where &nbsp; </span>
              </template>
              <template v-else>
                <span class="readOnlyExtraFeild" v-if="index1 != 0">and</span>
              </template>

              <!-- Select Filter Property -->
              <el-select class="smallDropdown" size="mini" v-if="!readOnly" v-model="subFilter.propertyColumn" filterable @change="onFilterPropertyChange(subFilter, selectedEvent.properties)" placeholder="Select Property">
                <!-- <el-option v-for="(property, index) in selectedEvent.properties" :key="index" :label="property.name" :value="property.name"></el-option> -->
                <el-option-group v-for="eventPropGroup in selectedEvent.properties" :key="eventPropGroup.columnName" :label="eventPropGroup.label">
                  <el-option v-for="(property, index) in eventPropGroup.options" :key="index" :label="property.name" :value="property.columnName"> </el-option>
                </el-option-group>
              </el-select>
              <span class="readOnlyFeild" v-else> {{ subFilter.propertyName }}</span>

              <!-- Select Comparision Type -->
              <!-- {{subFilter}} -->
              <template v-if="subFilter.comparisionTypeList">
                <el-select class="smallDropdown" size="mini" v-if="!readOnly" v-model="subFilter.comparisonType" filterable placeholder="Select Event Property">
                  <el-option v-for="(compType, index2) in subFilter.comparisionTypeList" :key="index2" :label="compType.label" :value="compType.key"></el-option>
                </el-select>
                <span class="readOnlyTypeFeild" v-else>
                  {{ getDisplayText(subFilter.comparisonType, subFilter.comparisionTypeList) }}
                </span>
              </template>
              <!-- {{subFilter}} -->

              <!-- Input Field List Based on types -->
              <template v-if="subFilter.comparisonType && subFilter.comparisonType != 'is not null' && subFilter.comparisonType != 'is null'">
                <div class="filterInputContainer">
                  <!-- Render Date For Date Type -->
                  <div class="inputSearch" v-if="subFilter.propertyInfo.type == 'date'">
                    <el-date-picker size="mini" v-model="subFilter.value" v-if="!readOnly" type="datetime" placeholder="Select date and time"> </el-date-picker>
                    <span class="readOnlyFeild" v-else>{{ subFilter.value }}</span>
                  </div>

                  <!-- Render Number input for number type -->
                  <div class="inputSearch" v-if="subFilter.propertyInfo.type == 'decimal' || subFilter.propertyInfo.type == 'number' || subFilter.propertyInfo.type == 'bigint'">
                    <el-input size="mini" placeholder="Enter value" v-if="!readOnly" v-model="subFilter.value"></el-input>
                    <span class="readOnlyFeild" v-else>{{ subFilter.value }}</span>
                  </div>

                  <!-- Render Searchable String for string type  -->
                  <div class="inputSearch" v-if="subFilter.propertyInfo.type == 'string'">
                    <el-autocomplete autocomplete="off" size="mini" placeholder="Enter search term" v-if="!readOnly" @focus="setCurrentEventData(selectedEvent.eventName, subFilter)" v-model="subFilter.value" :fetch-suggestions="fetchEventPropertySuggestion" clearable></el-autocomplete>
                    <span class="readOnlyFeild" v-else>{{ subFilter.value }}</span>
                  </div>

                  <!-- Boolean - Yes/No dropdown -->
                  <div v-if="subFilter.propertyInfo.type == 'boolean'">
                    <el-select size="mini" v-if="!readOnly" v-model="subFilter.value">
                      <el-option label="True/Yes" value="true"></el-option>
                      <el-option label="False/No" value="false"></el-option>
                    </el-select>
                    <span class="readOnlyFeild" v-else>{{ subFilter.value }}</span>
                  </div>
                </div>
              </template>

              <!-- Delete Button -->
              <el-button class="addFilterButton deleteBtn" v-if="!readOnly" icon="el-icon-delete" @click="deletePropertyFilter(selectedEvent.filters, index1)" type="text" size="mini"></el-button>
            </div>
          </div>

          <!-- Add More Filters Button -->
          <el-button type="primary" plain v-if="!readOnly && selectedEvent && selectedEvent.eventName" round class="mt-3" icon="el-icon-plus" size="mini" @click="addPropertyFilter(selectedEvent)">Add Event Filter</el-button>
        </div>
      </div>

      <!-- RFM Monetary Event Picker -->
      <div v-if="!readOnly && selectedEvent && selectedEvent.eventName" class="px-3 py-2 hidden" style="border-top: 1px solid #eef0f4">
        <el-checkbox v-model="usemonetaryEvent"> Add monetary event </el-checkbox>
        <div class="eventFilter" v-if="usemonetaryEvent">
          <el-select style="width: 200px" class="addNewEventBtn" size="mini" v-model="selectedmonetaryEvent.eventName" filterable @change="onMonetaryEventSelect()" placeholder="Select Action Event">
            <el-option-group v-for="eventGroup in eventList" :key="eventGroup.label" :label="eventGroup.label">
              <el-option v-for="eventName in eventGroup.options" :key="eventName" :label="eventName" :value="eventName"> </el-option>
            </el-option-group>
          </el-select>
          <span style="font-size: 12px" v-if="selectedmonetaryEvent.eventName"> &nbsp; agreegated by &nbsp; </span>
          <el-select style="width: 200px" class="addNewEventBtn" size="mini" v-if="selectedmonetaryEvent.eventName" v-model="selectedmonetaryEvent.attribute" filterable placeholder="Select Property">
            <el-option v-for="item in selectedmonetaryEvent.properties" :key="item.name" :label="item.name" :value="item.name" :disabled="!isValidMonetaryProperty(item.type)"> </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <!-- Loader for fetching data -->
    <div class="loader mt-8" v-if="fetchingData">
      <beat-loader :loading="fetchingData" color="rgb(220, 223, 230)" size="8px"></beat-loader>
    </div>
  </div>
</template>

<style lang="scss" src="./rfmEventFilter.scss"></style>

<script>
import rfmEventFilterComponent from './rfmEventFilterComponent';
export default rfmEventFilterComponent;
</script>
