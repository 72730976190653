<template>
  <div id="rfmPage">
    <div class="relative bg-white rounded-lg overflow-hidden border">
      <div class="inset-x-0 bg-gray-100 px-3 py-3 border-b">
        <div class="text-md">RFM Analysis</div>
      </div>
      <div class="queryBuilder">
        <RfmEventFilterComponent :showHeader="false" ref="rfmEventFilter"></RfmEventFilterComponent>

        <div class="hidden">
          <UserPropertyFilterComponent v-bind:filterTitle="' Users filters'" ref="userFilterComponent"></UserPropertyFilterComponent>
        </div>

        <div class="applyQuery">
          <el-button type="primary" size="small" :loading="fetchingData" @click="fetchData">Apply Changes</el-button>
          <div class="datePicker" style="float: right">
            <span class="datepickerInFilter">
              <el-select v-model="timingFilter" v-show="timingFilter != ''" size="small">
                <el-option v-for="(value, key) in rfmTimingOptions" :key="key" :label="value" :value="key"> </el-option>
              </el-select>
              <el-date-picker v-if="timingFilter == ''" v-model="dateFilterValues" size="mini" type="datetimerange" :picker-options="datePickerShortcutList" format="dd-MM-yyyy hh:mm:ss A" @change="fetchData"> </el-date-picker>
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Loader -->
    <div v-if="fetchingData" v-loading="true" class="border bg-white mt-4 rounded-md overflow-hidden text-center pt-10" style="height: 50vh">This may take a few seconds.</div>

    <!-- Rfm Chart -->
    <el-card class="rfmCard" v-if="data && !fetchingData">
      <div slot="header" class="clearfix">
        <span>RFM Report</span>
        <span style="float: right; padding: 3px 0">Total {{ getNumberWithComma(totalUsers) }} Users Performed {{ eventPerformed }} Event</span>
      </div>
      <div class="rfmChartWithAxis" style="display: flex; flex-direction: row">
        <div class="yAxis" style="display: inline-block; display: flex; flex-direction: row">
          <span class="yAxisText">Frequency Score</span>
          <div class="yAxisNumbersContainer">
            <div class="yAxisNumber" v-for="i in 5" :key="i">{{ 6 - i }}</div>
          </div>
        </div>
        <div class="chartWithYAxis" style="flex: 1">
          <div class="rfmChart">
            <div style="flex: 0.4; display: flex; flex-direction: column">
              <!-- Can Not Loose -->
              <el-tooltip popper-class="rfmTooltip" class="bucket" :style="{ 'background-color': data.cantLoose.color }" style="flex: 0.2" placement="right">
                <div slot="content">
                  <RfmTooltip :total="totalUsers" :data="data.cantLoose" @onCreateSegment="showCreateCampaignPopup"></RfmTooltip>
                </div>
                <div>
                  <div class="title1">{{ data.cantLoose.title }}</div>
                  <div class="description">
                    <template v-if="debugRFM">
                      {{ data.cantLoose.minFq }},{{ data.cantLoose.maxFq }}<br />
                      {{ data.cantLoose.minRc }},{{ data.cantLoose.maxRc }}<br />
                    </template>
                    <div>Users: {{ data.cantLoose.users }} ({{ parseInt((data.cantLoose.users / totalUsers) * 100) }}%)</div>
                    <div v-if="showMonetary">Avg. Monetary value: {{ data.cantLoose.monetary }}</div>
                  </div>
                </div>
              </el-tooltip>

              <!-- At Risk -->
              <el-tooltip popper-class="rfmTooltip" class="bucket" :style="{ 'background-color': data.atRisk.color }" style="flex: 0.4" placement="right">
                <div slot="content">
                  <RfmTooltip :total="totalUsers" :data="data.atRisk" @onCreateSegment="showCreateCampaignPopup"></RfmTooltip>
                </div>
                <div>
                  <div class="title1">At Risk</div>
                  <div class="description">
                    <template v-if="debugRFM">
                      {{ data.atRisk.minFq }},{{ data.atRisk.maxFq }}<br />
                      {{ data.atRisk.minRc }},{{ data.atRisk.maxRc }}<br />
                    </template>
                    <div>Users: {{ data.atRisk.users }} ({{ parseInt((data.atRisk.users / totalUsers) * 100) }}%)</div>
                    <div v-if="showMonetary">Avg. Monetary value: {{ data.atRisk.monetary }}</div>
                  </div>
                </div>
              </el-tooltip>

              <!-- Hibernating -->
              <el-tooltip popper-class="rfmTooltip" class="bucket" :style="{ 'background-color': data.hibernating.color }" style="flex: 0.4" placement="right">
                <div slot="content">
                  <RfmTooltip :total="totalUsers" :data="data.hibernating" @onCreateSegment="showCreateCampaignPopup"></RfmTooltip>
                </div>
                <div>
                  <div class="title1">Hibernating</div>
                  <div class="description">
                    <template v-if="debugRFM">
                      {{ data.hibernating.minFq }},{{ data.hibernating.maxFq }}<br />
                      {{ data.hibernating.minRc }},{{ data.hibernating.maxRc }}<br />
                    </template>
                    <div>Users: {{ data.hibernating.users }} ({{ parseInt((data.hibernating.users / totalUsers) * 100) }}%)</div>
                    <div v-if="showMonetary">Avg. Monetary value: {{ data.hibernating.monetary }}</div>
                  </div>
                </div>
              </el-tooltip>
            </div>
            <div style="flex: 0.6; display: flex; flex-direction: column">
              <div style="flex: 0.4; display: flex; flex-direction: row">
                <!-- Loyal Customers -->
                <el-tooltip popper-class="rfmTooltip" class="bucket" :style="{ 'background-color': data.loyalCustomers.color }" style="flex: 0.66" placement="left">
                  <div slot="content">
                    <RfmTooltip :total="totalUsers" :data="data.loyalCustomers" @onCreateSegment="showCreateCampaignPopup"></RfmTooltip>
                  </div>
                  <div>
                    <div class="title1">{{ data.loyalCustomers.title }}</div>
                    <div class="description">
                      <template v-if="debugRFM">
                        {{ data.loyalCustomers.minFq }},{{ data.loyalCustomers.maxFq }}<br />
                        {{ data.loyalCustomers.minRc }},{{ data.loyalCustomers.maxRc }}<br />
                      </template>
                      <div>Users: {{ data.loyalCustomers.users }} ({{ parseInt((data.loyalCustomers.users / totalUsers) * 100) }}%)</div>
                      <div v-if="showMonetary">Avg. Monetary value: {{ data.loyalCustomers.monetary }}</div>
                    </div>
                  </div>
                </el-tooltip>
                <!-- Champions -->
                <el-tooltip popper-class="rfmTooltip" class="bucket" :style="{ 'background-color': data.champions.color }" style="flex: 0.33" placement="left">
                  <div slot="content">
                    <RfmTooltip :total="totalUsers" :data="data.champions" @onCreateSegment="showCreateCampaignPopup"></RfmTooltip>
                  </div>
                  <div>
                    <div class="title1">{{ data.champions.title }}</div>
                    <div class="description">
                      <template v-if="debugRFM">
                        {{ data.champions.minFq }},{{ data.champions.maxFq }}<br />
                        {{ data.champions.minRc }},{{ data.champions.maxRc }}<br />
                      </template>
                      <div>Users: {{ data.champions.users }} ({{ parseInt((data.champions.users / totalUsers) * 100) }}%)</div>
                      <div v-if="showMonetary">Avg. Monetary value: {{ data.champions.monetary }}</div>
                    </div>
                  </div>
                </el-tooltip>
              </div>
              <div style="flex: 0.6; display: flex; flex-direction: row">
                <div style="flex: 0.33; display: flex; flex-direction: column">
                  <!-- Need Attention -->
                  <el-tooltip popper-class="rfmTooltip" class="bucket" :style="{ 'background-color': data.needAtention.color }" style="flex: 0.33" placement="left">
                    <div slot="content">
                      <RfmTooltip :total="totalUsers" :data="data.needAtention" @onCreateSegment="showCreateCampaignPopup"></RfmTooltip>
                    </div>
                    <div>
                      <div class="title1">{{ data.needAtention.title }}</div>
                      <div class="description">
                        <template v-if="debugRFM">
                          {{ data.needAtention.minFq }},{{ data.needAtention.maxFq }}<br />
                          {{ data.needAtention.minRc }},{{ data.needAtention.maxRc }}<br />
                        </template>
                        <div>Users: {{ data.needAtention.users }} ({{ parseInt((data.needAtention.users / totalUsers) * 100) }}%)</div>
                        <div v-if="showMonetary">Avg. Monetary value: {{ data.needAtention.monetary }}</div>
                      </div>
                    </div>
                  </el-tooltip>
                  <!-- About To Sleep -->
                  <el-tooltip popper-class="rfmTooltip" class="bucket" :style="{ 'background-color': data.aboutToSleep.color }" style="flex: 0.66" placement="left">
                    <div slot="content">
                      <RfmTooltip :total="totalUsers" :data="data.aboutToSleep" @onCreateSegment="showCreateCampaignPopup"></RfmTooltip>
                    </div>
                    <div>
                      <div class="title1">{{ data.aboutToSleep.title }}</div>
                      <div class="description">
                        <template v-if="debugRFM">
                          {{ data.aboutToSleep.minFq }},{{ data.aboutToSleep.maxFq }}<br />
                          {{ data.aboutToSleep.minRc }},{{ data.aboutToSleep.maxRc }}<br />
                        </template>
                        <div>Users: {{ data.aboutToSleep.users }} ({{ parseInt((data.aboutToSleep.users / totalUsers) * 100) }}%)</div>
                        <div v-if="showMonetary">Avg. Monetary value: {{ data.aboutToSleep.monetary }}</div>
                      </div>
                    </div>
                  </el-tooltip>
                </div>
                <div style="flex: 0.66; display: flex; flex-direction: column">
                  <!-- Potential Loyalists -->
                  <el-tooltip popper-class="rfmTooltip" class="bucket" :style="{ 'background-color': data.potentialLoyalists.color }" style="flex: 0.66" placement="left">
                    <div slot="content">
                      <RfmTooltip :total="totalUsers" :data="data.potentialLoyalists" @onCreateSegment="showCreateCampaignPopup"></RfmTooltip>
                    </div>
                    <div>
                      <div class="title1">{{ data.potentialLoyalists.title }}</div>
                      <div class="description">
                        <template v-if="debugRFM">
                          {{ data.potentialLoyalists.minFq }},{{ data.potentialLoyalists.maxFq }}<br />
                          {{ data.potentialLoyalists.minRc }},{{ data.potentialLoyalists.maxRc }}<br />
                        </template>
                        <div>Users: {{ data.potentialLoyalists.users }} ({{ parseInt((data.potentialLoyalists.users / totalUsers) * 100) }}%)</div>
                        <div v-if="showMonetary">Avg. Monetary value: {{ data.potentialLoyalists.monetary }}</div>
                      </div>
                    </div>
                  </el-tooltip>
                  <div style="flex: 0.33; display: flex; flex-direction: row">
                    <!-- Promising -->
                    <el-tooltip popper-class="rfmTooltip" class="bucket" :style="{ 'background-color': data.promising.color }" style="flex: 0.5" placement="left">
                      <div slot="content">
                        <RfmTooltip :total="totalUsers" :data="data.promising" @onCreateSegment="showCreateCampaignPopup"></RfmTooltip>
                      </div>
                      <div>
                        <div class="title1">{{ data.promising.title }}</div>
                        <div class="description">
                          <template v-if="debugRFM">
                            {{ data.promising.minFq }},{{ data.promising.maxFq }}<br />
                            {{ data.promising.minRc }},{{ data.promising.maxRc }}<br />
                          </template>
                          <div>Users: {{ data.promising.users }} ({{ parseInt((data.promising.users / totalUsers) * 100) }}%)</div>
                          <div v-if="showMonetary">Avg. Monetary value: {{ data.promising.monetary }}</div>
                        </div>
                      </div>
                    </el-tooltip>
                    <!-- New Customers -->
                    <el-tooltip popper-class="rfmTooltip" class="bucket" :style="{ 'background-color': data.newCustomers.color }" style="flex: 0.5" placement="left">
                      <div slot="content">
                        <RfmTooltip :total="totalUsers" :data="data.newCustomers" @onCreateSegment="showCreateCampaignPopup"></RfmTooltip>
                      </div>
                      <div>
                        <div class="title1">{{ data.newCustomers.title }}</div>
                        <div class="description">
                          <template v-if="debugRFM">
                            {{ data.newCustomers.minFq }},{{ data.newCustomers.maxFq }}<br />
                            {{ data.newCustomers.minRc }},{{ data.newCustomers.maxRc }}<br />
                          </template>
                          <div>Users: {{ data.newCustomers.users }} ({{ parseInt((data.newCustomers.users / totalUsers) * 100) }}%)</div>
                          <div v-if="showMonetary">Avg. Monetary value: {{ data.newCustomers.monetary }}</div>
                        </div>
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="xAxis">
            <div class="xAxisNumbersContainer">
              <div class="zeroPoint">0</div>
              <div class="xAxisNumber" v-for="i in 5" :key="i">{{ i }}</div>
            </div>
            <div class="xAxisText">Recency Score</div>
          </div>
        </div>
      </div>
    </el-card>

    <!-- Dialog - Create Segment -->
    <el-dialog custom-class="createSegmentDialog" title="Create Segment" :visible.sync="createSegmentDialogVisible" width="50%">
      <div>
        <!-- Segment Filter Input -->
        <el-input placeholder="Please Enter Segment Name Here" v-model="segmentName">
          <template slot="prepend">Segment Name</template>
        </el-input>

        <!-- Segment Criteria -->
        <el-card class="box-card segmentContainer" shadow="never">
          <div slot="header" class="clearfix">
            <span>Segment Criteria</span>
          </div>
          <div v-if="createSegmentDialogVisible">
            <UserPropertyFilterComponent v-bind:filterTitle="'Segment Criteria'" ref="createSegmentCriteria" v-bind:formattedPropertyFilter="userFilterForSegment" v-bind:readOnly="true"></UserPropertyFilterComponent>
          </div>
        </el-card>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="createSegment" :loading="creatingSegment">Create Segment</el-button>
        <el-button type="danger" plain @click="createSegmentDialogVisible = false">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import rfmComponent from './rfmComponent';
export default rfmComponent;
</script>
<style lang="scss" src="./rfm.scss"></style>
