<template>
  <div style="width: 200px">
    <div class="title">
      <div class="colorBox" :style="{ 'background-color': data.color }"></div>
      <div class="text">{{ data.title }}</div>
    </div>
    <div class="description">{{ data.description }}</div>
    <div class="divider"></div>

    <!-- User Details -->
    <div class="userInfo">
      <div class="title">Users</div>
      <div class="userItem">
        <div class="count">
          {{ getNumberWithComma(data.users) }}<span class="total"> /{{ getNumberWithComma(total) }}</span>
        </div>
        <div class="percentage">{{ parseInt((data.users / total) * 100) }}%</div>
      </div>
    </div>
    <div class="divider"></div>

    <!-- RFM Details -->
    <div class="rfmInfo">
      <div class="rfmItem">
        <div class="label">R (Last Activity)</div>
        <div class="value" v-if="data.maxRc">{{ data.maxRcDisplay }}</div>
        <div class="value" v-else>Very Recently</div>
      </div>
      <div class="rfmItem">
        <div class="label">F (Activity Count)</div>
        <div class="value" v-if="data.fqType == 'end'">{{ data.minFq }}+</div>
        <div class="value" v-else-if="data.fqType == 'start'">0-{{ data.maxFq }}</div>
        <div class="value" v-else-if="data.minFq == data.maxFq">
          {{ data.minFq }}
        </div>
        <div class="value" v-else>{{ data.minFq }}-{{ data.maxFq }}</div>
      </div>
      <div class="rfmItem" v-if="data.showMonetary">
        <div class="label">M (Monetary Value)</div>
        <div class="value">{{ data.monetary }}</div>
      </div>
    </div>
    <div class="divider"></div>

    <!-- Segment Button -->
    <el-button type="success" size="mini" class="segmentButton" @click="onCreateSegmentClicked()">Create Segment</el-button>
  </div>
</template>

<script>
export default {
  name: 'RfmTooltip',
  props: {
    data: {
      required: true
    },
    total: {
      required: true
    }
  },
  methods: {
    onCreateSegmentClicked() {
      this.$emit('onCreateSegment', this.data);
    }
  }
};
</script>

<style lang="scss">
.rfmTooltip {
  background-color: #343957 !important;

  .title {
    display: flex;

    .text {
      flex: 1;
      width: 150px;
      height: 20px;
      font-size: 14px;
      font-weight: bold;
      line-height: 17px;
      padding-left: 7px;
    }
    .colorBox {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      display: inline-block;
      //   background-color: red;
    }
  }
  .description {
    margin-top: 5px;
    font-size: 11px;
    line-height: 17px;
  }
  .divider {
    width: 100%;
    height: 1px;
    background: #dfdfdf;
    margin: 10px 0px;
  }

  .rfmInfo {
    margin-top: 10px;
    .rfmItem {
      font-size: 10px;
      line-height: 17px;
      display: flex;
      width: 100%;
      .label {
        flex: 1;
      }
      .value {
        flex: 1;
        text-align: right;
      }
    }
  }

  .userInfo {
    .title {
      font-size: 15px;
    }
    .userItem {
      display: flex;
      margin-top: 10px;
      .count {
        flex: 0.8;
        font-size: 17px;
        .total {
          font-size: 12px;
        }
      }
      .percentage {
        flex: 0.2;
        text-align: right;
        font-size: 13px;
      }
    }
  }

  .segmentButton {
    width: 100%;
  }
}
</style>
